.meta-info-col {
	.react__sticky-nav {
		@media (max-width: $mobile-width) {
			&.nav-affix {
				&--affix {
					top: 56px !important;
					width: 100% !important;
					background: #fff;
					left: 0;
					right: 0;
					z-index: 1;
					padding: 10px;
					box-shadow: 0 4px 2px -2px gray;
				}
			}
		}
		&.nav-affix {
			&--affix {
				position: fixed;
				top: 80px;
			}
		}
	}
	.curved-cta--max {
		width: 100%;
		a {
			font-size: 1rem;
		}
	}
	&__button {
		width: 100%;
		@media (max-width: $mobile-width) {
			margin-left: auto;
			margin-right: auto;
		}
	}
	&__booth-num {
		text-align: center;
		margin-bottom: 10px;
		padding: 10px 0;
		border-top: 1px solid black;
		border-bottom: 1px solid black;
		h4 {
			margin: 0;
		}
	}
	&__contact-list {
		p {
			//margin: 0;
		}
	}
	&__address {
		p {		
		    
		    margin-left: 20px;
		    
		    
			&:first-child {
				margin-left: 0;
			}

		}
	}
}
