$tracks: #CE3017, #A0388D ;
$tracks-lighter: lighten(#CE3017, 20), lighten(#A0388D, 20);
$main-color: #0a3d52;

.confman {
	&.conference-agenda--SPC {
		.curved-cta--primary {
			background-color: $main-color;
			.curved-cta__link {
				color: #fff;
			}
			&:hover {
				background-color: #fff;
				box-shadow: inset 0 0 0px 1.5px $main-color;
				.curved-cta__link {
					color: $main-color;
				}
			}
			&.curved-cta--hard {
				background: lighten($main-color, 40);
				&:hover,
				&.active {
					background: $main-color;
					box-shadow: unset;
					.curved-cta__link {
						color: #fff;
					}
				}
			}
		}
		.confman__main-nav {
			.main-nav__days {
				background-color: $main-color;
				.main-nav__nav-button {
					&.active,
					&:hover {
						transition: background-color 0.25s ease-in;
						-webkit-transition: background-color 0.25s ease-in;
						background-color: #31447b;
					}
					h3 {
						//font-family: motiva-sans;
					}
				}
			}
			.main-nav__tracks {
				background-color: #d7d7e6;
				.checkbox-filter {
					$i: 1;
					@each $track in $tracks {
						&.checkbox-filter--track-#{$i} {
							.bi-check-square-fill,
							.bi-square {
								color: nth($tracks, $i);
							}
						}
						$i: $i + 1;
					}
				}
			}
		}
		.react__body {
			.body__session {
				$i: 1;
				@each $track in $tracks {
					&.session--track-#{$i} {
						.session__flag {
							background-color: nth($tracks, $i);
							border-top: 15px solid nth($tracks-lighter, $i);
						}
						.session__title {
							h3 {
								color: nth($tracks, $i);
							}
						}
						.session__body {
							.body__speakers {
								& > h3 {
									color: nth($tracks, $i);
								}
								.speaker__info {
									&__name {
										color: nth($tracks, $i);
									}
									&__presentation {
										color: nth($tracks, $i);
									}
								}
							}
						}
					}
					$i: $i + 1;
				}
				.session__flag {
					h2 {
						//font-family: motiva-sans;
					}
				}
				.session__content {
					.session__title {
						.accordion-button {
							&::after {
								/*Chevron*/
							}
						}
					}
				}
			}
		}
		.conference-agenda__secondary-nav {
			.react__search {
				span {
					background-color: $main-color;
					cursor: pointer;
					transition: background-color 0.15s ease-in-out;
					&:hover {
						background-color: lighten($main-color, 20);
					}
				}
			}
		}
	}
	&.speaker-list--SPC {
		.speaker {
			&__name {
				color: $main-color;
			}
		}
	}
}
